import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert, Modal, Button } from "react-bootstrap";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import * as FileSaver from "file-saver";
import * as ExcelJS from 'exceljs';
import DataTable from 'react-data-table-component';

const Production = () => {
    const [token, setToken] = useState("");
    const [userLogId, setUserLogId] = useState("")
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
    const [isLoading, setIsLoading] = useState(false);
    const [newStartDate, setNewStartDate] = useState()
    const [newEndDate, setNewEndDate] = useState()
    const [fromDate, setFromDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
    const [toDate, setToDate] = useState(new Date(moment(new Date())));
    const [resultant, setResultant] = useState({ headers: [], data: [] });
    const [filteredData, setFilteredData] = useState({ headers: [], data: [] });
    const [searchText, setSearchText] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false); // State to control confirmation modal
    const [paraDelete, setParaDelete] = useState(); // State to track the parameter being deleted

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    useEffect(() => {
        const tkData = Cookies.get("tkData");
        if (tkData) {
            const tokenData = JSON.parse(tkData);
            setToken(tokenData.token);
            setUserLogId(tokenData.userLogId)
        }
    }, []);

    const [alerts, setAlerts] = React.useState([]);
    // Function to show flash message with a delay
    const showFlashMessage = (message, variant = "danger") => {
      const newAlert = {
        message: message,
        variant: variant,
        id: new Date().getTime(),
      };
  
      setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
    };
  
    useEffect(() => {
      if (alerts.length > 0) {
        const timeoutId = setTimeout(() => {
          setAlerts((prevAlerts) => prevAlerts.slice(1));
        }, 2000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [alerts]);

    /////////// for table ///////////

    const fetchData = async () => {
        setIsLoading(true);
        if (!token) {
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_VARIABLE}/iotrms/production_jsw/JSW_parameters_table`,
                    {
                        access_token: token,
                        user_log_id: userLogId,
                    }
                );

                if (response.data.success) {
                    setResultant(transformData(response.data));
                    success = true;
                    setNewStartDate(breadcrum_date_converter(response.data.start_date));
                    setNewEndDate(breadcrum_date_converter(response.data.end_date));
                } else {
                    setResultant(transformData(response.data));
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Network error:', error);
                setIsLoading(false);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Data Not Available");
        }
    };// this effect runs only once


    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleParametersAdded = () => {
        fetchData();
    };

    useEffect(() => {
        setFilteredData(resultant)
        return () => {
        }
    }, [resultant])


    useEffect(() => {
        searchTable();
    }, [searchText]);

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const breadcrum_date_converter = (date) => {
        let dateString = date;
        let [datePart, timePart] = dateString.split(' ');
        let [day, month, year] = datePart.split('-');
        let [hours, minutes] = timePart.split(':');
        let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return myDate;
    }

    const breadcrum_date_converter_after_submit = (date) => {
        let dateString = date;
        let [datePart, timePart] = dateString.split(' ');
        let [year, month, day] = datePart.split('-');
        let [hours, minutes] = timePart.split(':');
        let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
        return myDate;
    }

    // truncate to two decimal limt
    const truncateToTwoDigits = (value) => {
        if (!isNaN(value)) {
            return Math.trunc(value * 100) / 100;
        }
        return value;
    };

    // round off to nearest value
    const roundOf = (value) => {
        if (!isNaN(value)) {
            return Math.round(value)
        }
        return value;
    };

    const searchTable = () => {
        if (searchText === undefined || searchText === null || searchText === "" || !searchText) {
            setFilteredData(resultant);
        } else if (resultant && resultant.data) {
            const filteredResult = resultant.data.filter((row) => {
                row = Object.values(row);
                const rowValues = row.map((item) =>
                    typeof item === "string" ? item.toLowerCase() : item.toString()
                );
                const searchTerm = searchText.toLowerCase();
                return rowValues.some((value) => value.includes(searchTerm));
            });
            setFilteredData((prevOptions) => ({
                ...prevOptions,
                data: filteredResult,
            }));
        }
    };

    const exportToCSV = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('data');
        const customHeadings = filteredData.headers.map((column) => column.name);

        worksheet.addRow(customHeadings);

        filteredData.data.forEach((row) => {
            const rowData = customHeadings.map((heading) => {
                const value = row[heading.toLowerCase()];
                return typeof value === 'number' ? roundOf(value) : value;
            });
            worksheet.addRow(rowData);
        });

        // Set background color and font color for header cells
        // worksheet.getRow(1).eachCell((cell) => {
        // cell.fill = {
        //     type: 'pattern',
        //     pattern: 'solid',
        // fgColor: { argb: '8DB4E2' }
        // }
        // });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const dataBlob = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(dataBlob, 'Ems Production.xlsx');
        });
    };
    const replaceDotInColumnNames = (columnName) => {
        return columnName.replace(/_dot_/g, ".");
    };

    const transformData = (resp) => {
        let data = resp.data.data;
        let headers = resp.data.headers;

        const finalColumns = [
            ...headers.map((value) => {
                const formattedValue = replaceDotInColumnNames(value);
                return {
                    name: formattedValue,
                    selector: (row) => row[formattedValue.toLowerCase().replace(/_/g, " ")],
                    sortable: true,
                    cell: (row) => (
                        <div className="custom-cell">
                            {row[formattedValue.toLowerCase()] !== "" ? truncateToTwoDigits(row[formattedValue.toLowerCase()]) : ""}
                        </div>
                    ),
                };
            }),
        ];
        const isWithinLast48Hours = moment().diff(moment(data[0], 'DD-MM-YYYY HH:mm:ss'), 'hours') <= 48;
        if (resp.success === true && isWithinLast48Hours) {
            finalColumns.unshift({
                name: "",
                selector: "actions",
                cell: (row) => {
                    // const isWithinLast48Hours = moment().diff(moment(row['datetime'], 'DD-MM-YYYY HH:mm:ss'), 'hours') <= 48;
                    return isWithinLast48Hours ? (
                        <i
                            className='fa fa-trash'
                            onClick={() => handleDeleteRow(row['datetime'])}
                            style={{ cursor: 'pointer', color: 'red' }}
                        />
                    ) : null;
                },
            });
        }

        const finalRows = data.map((row) => {
            const rowData = {};
            resp.data.headers.forEach((columnName, index) => {
                const formattedColumnName = replaceDotInColumnNames(columnName);
                rowData[formattedColumnName.toLowerCase().replace(/_/g, " ")] = row[index];
            });
            return rowData;
        });

        return { headers: finalColumns, data: finalRows };
    };


    const convertDateCloneApi = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
        const day = String(inputDate.getDate()).padStart(2, "0");
        const hours = String(inputDate.getHours()).padStart(2, "0");
        const minutes = String(inputDate.getMinutes()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        return formattedDate;
    };

    const convertDateTimeFormat = (datetime) => {
        const formattedDateTime = moment(datetime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
        return formattedDateTime;
    };

    const fetchDataWithDate = async (props) => {
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;

        while (attempts < maxAttempts && !success) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_VARIABLE}/iotrms/production_jsw/JSW_parameters_table`,
                    props
                );
                // Check if the request was successful
                if (response.data.success) {
                    setResultant(transformData(response.data))
                    success = true;
                    // setNewStartDate(breadcrum_date_converter(response.data.start_date));
                    // setNewEndDate(breadcrum_date_converter(response.data.end_date));
                    // showFlashMessage("Data Successfully Updated", "success");
                }
                else if (response.data.success === false) {
                    // showFlashMessage("Data Not Available");
                    setResultant(transformData(response.data))
                    // showFlashMessage("Data Successfully Updated", "success");
                }
                else {
                    // showFlashMessage("Data Not Available");
                    console.error('Failed to fetch data');
                }
                setIsLoading(false)
            } catch (error) {
                // Handle any network errors
                // showFlashMessage("Data Not Available");
                console.error('Network error:', error);
            }
            attempts++;
        }
        if (!success) {
            showFlashMessage("Data Not Available");
        }
    };

    const deleteParameter = async (datetime) => {
        const dt = convertDateTimeFormat(datetime)
        if (!token) {
            // Return early if token is not available
            return;
        }

        let attempts = 0;
        const maxAttempts = 3;
        let success = false;
        while (attempts < maxAttempts && !success) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_VARIABLE}/iotrms/production_jsw/delete_JSW_parameters`,
                    {
                        access_token: token,
                        user_log_id: userLogId,
                        datetime: dt
                    }
                );
                if (response.data.success) {
                    showFlashMessage(response.data.msg, "success");
                    fetchData();
                    success = true;
                } else if (response.data.success === false) {
                }
                else {
                    console.error("Failed to delete parametrs");
                }
            } catch (error) {
                console.error('Error deleting row:', error);
            }
            attempts++;
            setIsLoading(false);
        }
        if (!success) {
            showFlashMessage("Parameters Not Deleted Successfully.", "danger");
        }
    };

    const updateFromDate = (newFromDate) => {
        setFromDate(newFromDate);
        setNewStartDate("");
    };
    const updateToDate = (newToDate) => {
        setToDate(newToDate);
        setNewEndDate("");
    };

    // Custom styles for DataTable
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                whiteSpace: 'normal',// Custom font weight for the table header
            },
        },
        headRows: {
            style: {
                whiteSpace: 'normal',
            },
        }
    };

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL",
    };

    const handleSubmitData = (props) => {
        let data = {
            access_token: token,
            user_log_id: userLogId,
            start_date: convertDateCloneApi(props.fromDate),
            end_date: convertDateCloneApi(props.toDate),
            // iot_sol: "EMS"
        }

        setNewStartDate(breadcrum_date_converter_after_submit(convertDateCloneApi(fromDate)))
        setNewEndDate(breadcrum_date_converter_after_submit(convertDateCloneApi(toDate)))
        fetchDataWithDate(data);
    }

    const handleDeleteRow = (datetime) => {
        setParaDelete(datetime); // Store the datetime to delete
        setShowConfirmation(true); // Show confirmation modal
    };
    const handleConfirm = async () => {
        setShowConfirmation(false); // Close the confirmation modal
        await deleteParameter(paraDelete); // Perform deletion action
    };

    const NoDataComponent = () => (
        <>
            <table className="noDataTbl table dtr-inline" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        {filteredData.headers.map((column) => (
                            <th key={column.name}>{column.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={filteredData.headers.length}>
                            <div className="text-center">
                                <p>No Data Available</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );


    return (
        <>
            {/* Confirmation Modal */}
            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <img
                        src="assets/images/motware_logo.png"
                        alt="motware Logo"
                        width="40px"
                        className="brand-image"
                    />
                    <h5 className="modal-title w-100 text-center">Production</h5>
                </Modal.Header>
                <Modal.Body><div className="text-center">Please Confirm if you want to delete the row?</div></Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={handleConfirm}>
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
            {isLoading && <div className='loaderWpr'>
                <div className="loader"></div>
            </div>}
           
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
            <div className="shopPgWpr content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <div className="mdlContent card pr-card mb30">
                            <div className="breadcrumbHdr card-header">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            {" "}
                                            {newStartDate ? (
                                                <a href="#">{newStartDate}</a>
                                            ) : (
                                                <a href="#">{formatDate(fromDate)}</a>
                                            )}
                                            &nbsp;-&nbsp;
                                            {newEndDate ? (
                                                <a href="#">{newEndDate}</a>
                                            ) : (
                                                <a href="#">{formatDate(toDate)}</a>
                                            )}
                                        </a>
                                    </li>
                                </ol>
                            </div>

                            <div className="mdlContent card-body col-md-12 col-lg-12 mt-2">
                                <div className="card pr-card mt-2">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 col-lg-6 col-xl-6">
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-inline dataTables_filter">
                                                    <div id="" className="mr10">
                                                        <label>
                                                            <input
                                                                type="search"
                                                                // value={searchText}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search..."
                                                                onChange={(e) => {
                                                                    setSearchText(e.target.value);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className="input-group pr-form-group" >
                                                        <a className="" alt="Download XLS" onClick={exportToCSV}>
                                                            <i className="fa fa-file-excel-o" ></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={filteredData.headers}
                                            data={filteredData.data}
                                            pagination
                                            fixedHeader
                                            paginationComponentOptions={paginationComponentOptions}
                                            noDataComponent={<NoDataComponent />}
                                            customStyles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <Sidebar
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                handleSubmitData={handleSubmitData}
                updateFromDate={updateFromDate}
                updateToDate={updateToDate}
                handleParametersAdded={handleParametersAdded}
            />

        </>
    );
};

export default Production;
