import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Login from "./Login/login";
import SummaryPage from "./components/Selco/Summary/Summary";
import Cookies from "js-cookie";
import Sidebar from "./components/Sidebar/Sidebar";
import Summary_PDF from "./components/Selco/Summary/MainContent/Summary_PDF";
import EmptyPage from "./components/Sidebar/EmptyPage";
import EnergyConsum from "./components/Selco/EnergyConsum/EnergyConsum";
import SystemBlockDiagram from "./components/Selco/SystemBlogDiagrams/SystemBlockDiagram";
import Trend from "./components/Selco/Trend/Trend";
import DailySummary from "./components/Selco/EnergyParameters/DailySummary";
import TransformerSummary from "./components/Transformer/Summary/Summary";
import TransformerTrend from "./components/Transformer/Trend/Trend";
import TransformerDailySummary from "./components/Transformer/Daily Summary/DailySummary";
import EnergyDelivered from "./components/Transformer/Energy Delivered/EnergyDelivered";
import Insights from "./components/Transformer/Insights/Insights";
import Hotspot from "./components/Transformer/Hotspot/Hotspot";
import DemandGraph from "./components/Transformer/Demand Graph/DemandGraph";
import PowerFactor from "./components/Transformer/Power Factor/PowerFactor";
import AqiqSummary from "./components/Aqiq/Summary/Summary";
import AqiqTrend from "./components/Aqiq/Trend/Trend";
import HvacSummary from "./components/Hvac/Summary/Summary";
import HvacTrend from "./components/Hvac/Trend/Trend";
import OtherSummary from "./components/Other/Summary/Summary";
import OtherTrend from "./components/Other/Trend/Trend";
import OtherPh from "./components/Other/Ph/Ph";
import MotorSummary from "./components/Motor/Summary/Summary";
import MotorTrend from "./components/Motor/Trend/Trend";
import EmsSummary from "./components/Ems/Summary/Summary";
import EmsTrend from "./components/Ems/Trend/Trend";
import EnergySummary from "./components/Ems/EnergySummary/EnergySummary";
import Sld from "./components/Ems/Sld/Sld";
import Shop from "./components/Ems/Shop/Shop";
import UpsSummary from "./components/Ups/Summary/Summary";
import UpsTrend from "./components/Ups/Trend/Trend";
import solutionContext from "./components/context/solution/solutionContext";
import EnergyLoss from "./components/Transformer/Energy Loss/EnergyLoss";
import ResidualLife from "./components/Transformer/Residual Life/ResidualLife";
import CopperLoss from "./components/Transformer/Copper Loss/CopperLoss";
import { Alert } from "react-bootstrap";
import Busbar from "./components/Transformer/Busbar/Busbar";
import Vehicles from "./components/CableIQ/Vehicles";
import Cables from "./components/CableIQ/Cables";
import RouteMap from "./components/CableIQ/RouteMap/RouteMap";
import VideoPlayer from "./components/CableIQ/VideoPlayer";
import Production from "./components/Ems/Porduction/Production";
import { ResetAxesProvider } from './components/context/ReactAxesContext';

function App() {
  const navigate = useNavigate();
  const [inactive, setInactive] = useState(false);
  const isUserLoggedIn = Cookies.get("islogin") === "true";
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn);
  const { solution_id, update_solution } = useContext(solutionContext);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");

  const redirectToDashboard = (inputValue) => {
    Cookies.set("tkData", "true");
    Cookies.set("inputValue", inputValue);
    setIsLoggedIn(true);
    navigate("/");
  };

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
    // eslint-disable-next-line
  }, []);

  const [sidebar, setSidebar] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [redirectToPage, setRedirectToPage] = useState("");

  const getSidebarMenu = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/filter/sidebar_menu`,
        {
          access_token: token,
          user_log_id: userLogId,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      );

      setSidebar(response.data.data);
      const firstEnabledPage = response.data.data[0].reports.find(
        (item) => item.cust_report_status === "enable"
      );

      if (firstEnabledPage) {
        const solution_name = response.data.data[0].iot_sol;
        setRedirectToPage(solution_name + " " + firstEnabledPage.name);
      } else {
        setRedirectToPage("");
      }
    } catch (error) {
      console.error("Error fetching gateway data:", error);
    }
  };

  useEffect(() => {
    if (token) {
      getSidebarMenu();
    }
  }, [token, redirectToPage]);

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  useEffect(() => {
    // Display welcome message when redirectToPage is set and not previously shown
    const welcomeMessageShown = Cookies.get("welcomeMessageShown");

    if (redirectToPage && !welcomeMessageShown) {
      showFlashMessage("Welcome to Motware", "success");
      Cookies.set("welcomeMessageShown", "true");
    }
  }, [redirectToPage]);

  return (
    <>
     {alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        <ResetAxesProvider>
      <div>
        {isLoggedIn && !window.location.pathname.startsWith("/pdf") && (
          <Sidebar onCollapse={(inactive) => setInactive(inactive)}  />
        )}

        <Routes>
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <Login redirectToDashboard={redirectToDashboard} />
              )
            }
          />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to={`/${redirectToPage}`} />
              ) : (
                <Login redirectToDashboard={redirectToDashboard} />
              )
            }
          />

          {/* Selco Routes */}
          <Route
            path="/selco summary"
            element={isLoggedIn ? <SummaryPage /> : <Navigate to="/" />}
          />
          <Route
            path="/selco Daily Energy Summary"
            element={isLoggedIn ? <DailySummary /> : <Navigate to="/" />}
          />
          <Route
            path="/selco system block diagram"
            element={isLoggedIn ? <SystemBlockDiagram /> : <Navigate to="/" />}
          />
          <Route
            path="/selco not_found_page"
            element={isLoggedIn ? <EmptyPage /> : <Navigate to="/" />}
          />
          <Route
            path="/selco trend"
            element={isLoggedIn ? <Trend /> : <Navigate to="/" />}
          />
          <Route
            path="/selco energy consumption"
            element={isLoggedIn ? <EnergyConsum /> : <Navigate to="/" />}
          />
          <Route
            path="/pdf"
            element={isLoggedIn ? <Summary_PDF /> : <Navigate to="/" />}
          />

          {/* Transformer Routes */}
          <Route
            path="/transformer summary"
            element={isLoggedIn ? <TransformerSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer trend"
            element={isLoggedIn ? <TransformerTrend /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer daily summary"
            element={
              isLoggedIn ? <TransformerDailySummary /> : <Navigate to="/" />
            }
          />
          <Route
            path="/transformer demand graph"
            element={isLoggedIn ? <DemandGraph /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer insights"
            element={isLoggedIn ? <Insights /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer energy delivered"
            element={isLoggedIn ? <EnergyDelivered /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer hotspot"
            element={isLoggedIn ? <Hotspot /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer power factor"
            element={isLoggedIn ? <PowerFactor /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer energy loss"
            element={isLoggedIn ? <EnergyLoss /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer residual life"
            element={isLoggedIn ? <ResidualLife /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer copper loss"
            element={isLoggedIn ? <CopperLoss /> : <Navigate to="/" />}
          />
          <Route
            path="/transformer busbar"
            element={isLoggedIn ? <Busbar /> : <Navigate to="/" />}
          />

          {/* Aqiq Routes */}
          <Route
            path="/aqiq summary"
            element={isLoggedIn ? <AqiqSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/aqiq trend"
            element={isLoggedIn ? <AqiqTrend /> : <Navigate to="/" />}
          />

          {/* HVAC Routes */}
          <Route
            path="/hvac summary"
            element={isLoggedIn ? <HvacSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/hvac trend"
            element={isLoggedIn ? <HvacTrend /> : <Navigate to="/" />}
          />

          {/* Other Routes */}
          <Route
            path="/other summary"
            element={isLoggedIn ? <OtherSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/other trend"
            element={isLoggedIn ? <OtherTrend /> : <Navigate to="/" />}
          />
          <Route
            path="/other ph"
            element={isLoggedIn ? <OtherPh /> : <Navigate to="/" />}
          />

          {/* Motor Routes */}
          <Route
            path="/motor summary"
            element={isLoggedIn ? <MotorSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/motor trend"
            element={isLoggedIn ? <MotorTrend /> : <Navigate to="/" />}
          />

          {/* EMS Routes */}
          <Route
            path="/ems summary"
            element={isLoggedIn ? <EmsSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/ems trend"
            element={isLoggedIn ? <EmsTrend /> : <Navigate to="/" />}
          />
          <Route
            path="/ems energy summary"
            element={isLoggedIn ? <EnergySummary /> : <Navigate to="/" />}
          />
          <Route
            path="/ems sld"
            element={isLoggedIn ? <Sld /> : <Navigate to="/" />}
          />
          <Route
            path="/ems shop"
            element={isLoggedIn ? <Shop /> : <Navigate to="/" />}
          />
          <Route
            path="/ems production"
            element={isLoggedIn ? <Production /> : <Navigate to="/" />}
          />

          {/* UPS Routes */}
          <Route
            path="/ups summary"
            element={isLoggedIn ? <UpsSummary /> : <Navigate to="/" />}
          />
          <Route
            path="/ups trend"
            element={isLoggedIn ? <UpsTrend /> : <Navigate to="/" />}
          />

          {/* CableIQ Routes */}
          <Route
            path="/cable vehicle"
            element={isLoggedIn ? <Vehicles /> : <Navigate to="/" />}
          />
          <Route
            path="/cable cable"
            element={isLoggedIn ? <Cables /> : <Navigate to="/" />}
          />
          <Route
            path="/cable routes"
            element={isLoggedIn ? <RouteMap /> : <Navigate to="/" />}
          />
          <Route
            path="/video player"
            element={isLoggedIn ? <VideoPlayer /> : <Navigate to="/" />}
          />
        </Routes>
      </div>
      </ResetAxesProvider>
    </>
  );
}

export default App;
