import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import solutionContext from "../context/solution/solutionContext";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import AWS from "aws-sdk";
import { Link, useNavigate } from "react-router-dom";

// Configure the AWS SDK with your credentials and region
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

function Vehicles() {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { solution_id, update_solution } = useContext(solutionContext);
  const [solution, setSolution_id] = useState(solution_id);
  const [defaultDate, setDefaultDate] = useState(getCurrentDate());
  const [alerts, setAlerts] = useState([]);
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataTableData, setOriginalDataTableData] = useState([]);
  const [signedUrls, setSignedUrls] = useState([]);
  const [error, setError] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoRef = useRef(null);

  useEffect(() => {
    setDefaultDate(getCurrentDate());
  }, []);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (solution === null) {
      setSolution_id(localStorage.getItem("solution"));
      update_solution(localStorage.getItem("solution"));
    }
  }, []);

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const fetchTableData = useCallback(async () => {
    if (!token) {
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setFilteredDataTableData([]);
    setOriginalDataTableData([]);
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/vehicles_table`,
          {
            access_token: token,
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success) {
          setFilteredDataTableData(response.data.data?.data || []);
          setOriginalDataTableData(response.data.data?.data || []);
          success = true;
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Data not availbale for Vehical";
          showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Data not availbale for Vehical");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Data not availbale for Vehical");
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await fetchTableData();
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      const delay = 200;
      const timerId = setTimeout(() => {
        fetchData();
      }, delay);

      return () => clearTimeout(timerId);
    }
  }, [token]);

  const generateSignedUrls = async () => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Prefix: process.env.REACT_APP_S3_FOLDER + "/",
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const mp4Files = data.Contents.filter((obj) => obj.Key.endsWith(".mp4"));

      const urls = await Promise.all(
        mp4Files.map(async (obj) => {
          const signedUrlParams = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: obj.Key,
          };
          const url = await s3.getSignedUrlPromise(
            "getObject",
            signedUrlParams
          );
          return { key: obj.Key, url };
        })
      );

      setSignedUrls(urls);
    } catch (err) {
      console.error("Error listing or generating signed URLs:", err);
      setError(err);
    }
  };

  useEffect(() => {
    generateSignedUrls();
  }, []);
  useEffect(() => {
    const refreshInterval = 2 * 1000;

    const refresh = () => {
      generateSignedUrls();
      const timeoutId = setTimeout(refresh, refreshInterval);
      return () => clearTimeout(timeoutId);
    };

    const timeoutId = setTimeout(refresh, refreshInterval);
    return () => clearTimeout(timeoutId);
  }, [generateSignedUrls]);
  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % signedUrls.length;
      if (videoRef.current) {
        videoRef.current.load(); // reload video element with new source
      }
      return newIndex;
    });
  };

  const handleLiveStreamingClick = (vehicleNo) => {
    localStorage.setItem("selectedVehicle", vehicleNo);
    navigate(`/video player`);
  };

  const tableColumns = [
    {
      name: "Vehicle No",
      selector: (row) => row[1]|| "-",
      sortable: true,
    },
    {
      name: "Make",
      selector: (row) => row[2]|| "-",
      sortable: true,
    },
    {
      name: "Model",
      selector: (row) => row[3]|| "-",
      sortable: true,
    },
    {
      name: "Engine No",
      selector: (row) => row[4]|| "-",
      sortable: true,
    },
    {
      name: "Chassis No",
      selector: (row) => row[5]|| "-",
      sortable: true,
    },
    {
      name: "Metrix Number",
      selector: (row) => row[6]|| "-",
      sortable: true,
    },
    {
      name: "Live Streaming",
      selector: (row) => (
        <Link to={`/video player`} style={{ color: "#000", fontSize: "16px" }}>
          <i
           onClick={() => handleLiveStreamingClick(row[1])}
            className="fa fa-video-camera"
            title="View"
            style={{ cursor: "pointer" }}
          ></i>
        </Link>
      ),
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const VehicalInitialValue = {
    vehicle_no: "",
    make: "",
    model: "",
    engine_no: "",
    chassis_no: "",
    metrix_serial_number: "",
    expiry_date_of_token: defaultDate,
  };

  const VehicalFormSchema = Yup.object({
    vehicle_no: Yup.string().required("Please Enter Vehical Number!"),
    make: Yup.string().required("Please Enter Make!"),
  });
  const VehicalFormik = useFormik({
    initialValues: VehicalInitialValue,
    validationSchema: VehicalFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      // if (!isValid) {
      //   return;
      // }
      if (token)
        if (!values.vehicle_no || !values.make) {
          showFlashMessage("Please fill in the required fields.", "danger");
          return;
        }
      let payload;
      try {
        if (token) {
          payload = {
            access_token: token,
            user_log_id: userLogId,
            vehicle_no: values.vehicle_no,
            make: values.make,
            model: values.model,
            engine_no: values.engine_no,
            chassis_no: values.chassis_no,
            metrix_serial_number: values.metrix_serial_number,
            expiry_date_of_token: values.expiry_date_of_token,
          };
          //         // Fetch the latitude and longitude from the address if they are available

          let res = await fetch(
            `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/add_van_data`,
            {
              method: "POST",
              body: JSON.stringify(payload),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );

          const data = await res.json();
          if (data.success === true) {
            showFlashMessage("Cable Vehical successfully added", "success");
            fetchTableData();
            resetForm();
            handleFormReset();

            return;
          }

          //         // Process the successful response here
        }
      } catch (err) {
        console.error("Error during API call:", err);
        showFlashMessage(
          "Something went wrong, could not add cable vehical.",
          "danger"
        );
      }
    },
  });

  const handleFormReset = () => {
    VehicalFormik.resetForm();
  };

  const NoDataComponent = () => (
    <table className="noDataTbl table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Vehicle No</th>
          <th>Make</th>
          <th>Model</th>
          <th>Engine No</th>
          <th>Chassis No</th>
          <th>Metrix Version</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={tableColumns.length}>
            <div className="text-center">
              <p>No Data Available</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal",
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  const handleSearch = (searchText) => {
    const searchTextLower = searchText.toLowerCase();
    const filteredData = originalDataTableData.filter((item) => {
      return (
        item[1].toLowerCase().includes(searchTextLower) ||
        item[2].toLowerCase().includes(searchTextLower) ||
        item[3].toString().toLowerCase().includes(searchTextLower) ||
        item[4].toString().toLowerCase().includes(searchTextLower) ||
        item[5].toString().toLowerCase().includes(searchTextLower) ||
        item[6].toString().includes(searchTextLower)
      );
    });
    setFilteredDataTableData(filteredData);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const customHeadings = tableColumns.map((column) => column.name);
    const filteredDataExcel = [];
    filteredDataTableData.map((row) => {
      filteredDataExcel.push({
        "Vehical No": row[1],
        Make: row[2],
        Model: row[3],
        "Engine No": row[4],
        "Chassis No": row[5],
        "Matrix Version": row[6],
      });
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "Cable Vehical" + fileExtension);
  };

  return (
    <>
      <div className="content-wrapper">
        {isLoading && (
          <div className="loaderWpr">
            <div className="loader"></div>
          </div>
        )}
        
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item ">
                        <a>Vehicle</a>
                      </li>
                    </ol>
                  </div>

                  <div className="mdlContent card-body">
                    <div className="col-md-12 col-lg-12">
                      <div className="card pr-card mb30">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="dataTables_length" id=""></div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="form-inline dataTables_filter">
                                <div className="input-group pr-form-group">
                                  <a
                                    className="btn btn-c-outline-primary mr10"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addVehical"
                                    style={{ padding: "0px 20px" }}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      style={{ fontSize: "14px" }}
                                    ></i>{" "}
                                    Vehicle
                                  </a>
                                </div>
                                <div className="mr10">
                                  <label>
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search..."
                                      value={searchText}
                                      onChange={(e) => {
                                        const searchText = e.target.value;
                                        setSearchText(searchText);
                                        handleSearch(searchText);
                                      }}
                                    />
                                  </label>
                                </div>
                                <div
                                  className="input-group pr-form-group"
                                  id=""
                                >
                                  <a
                                    className=""
                                    alt="Download XLS"
                                    onClick={exportToCSV}
                                  >
                                    <i className="fa fa-file-excel-o"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <DataTable
                            columns={tableColumns}
                            data={filteredDataTableData}
                            pagination
                            fixedHeader
                            paginationComponentOptions={
                              paginationComponentOptions
                            }
                            noDataComponent={<NoDataComponent />}
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      {signedUrls.length > 0 && (
                        <div>
                          <h2>Playlist</h2>
                          <ul>
                            {signedUrls.map((item, index) => (
                              <li key={index} style={{ fontWeight: index === currentVideoIndex ? "bold" : "normal" }}>
                                {item.key}
                              </li>
                            ))}
                          </ul>
                          <video
                            ref={videoRef}
                            width="600"
                            controls
                            autoPlay
                            onEnded={handleVideoEnd}
                          >
                            <source src={signedUrls[currentVideoIndex].url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="addVehical"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addVehical"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <img
                src="assets/images/motware_logo.png"
                alt="motware Logo"
                width="40px"
                className="brand-image"
              />
              <h5 className="modal-title w-100 text-center">Add New Vehicle</h5>
              <button
                onClick={handleFormReset}
                type="button"
                className="close"
                aria-label="Close"
                data-bs-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Vehicle No*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Vehical Number"
                          name="vehicle_no"
                          value={VehicalFormik.values.vehicle_no}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Make*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Enter Make"
                          name="make"
                          value={VehicalFormik.values.make}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Model
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Enter Model"
                          name="model"
                          value={VehicalFormik.values.model}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Engine No
                        </label>
                        <input
                          type="text"
                          className="form-control number-input"
                          id=""
                          placeholder="Enter Engine No"
                          name="engine_no"
                          value={VehicalFormik.values.engine_no}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Chassis No
                        </label>
                        <input
                          type="text"
                          className="form-control number-input"
                          id=""
                          placeholder="Enter Chassis No"
                          name="chassis_no"
                          value={VehicalFormik.values.chassis_no}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Metrix Version
                        </label>
                        <input
                          type="text"
                          className="form-control number-input"
                          id=""
                          placeholder="Enter Metrix Version"
                          name="metrix_serial_number"
                          value={VehicalFormik.values.metrix_serial_number}
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="col-form-label">
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="expiry_date_of_token"
                          value={
                            VehicalFormik.values.expiry_date_of_token ||
                            defaultDate
                          }
                          onChange={VehicalFormik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt20 mb10">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={VehicalFormik.handleSubmit}
                    data-bs-dismiss={
                      VehicalFormik.values.vehicle_no &&
                      VehicalFormik.values.make
                        ? "modal"
                        : ""
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vehicles;
