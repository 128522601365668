import React, { useEffect, useState, useCallback, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import solutionContext from "../context/solution/solutionContext";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"; 
import moment from "moment";
import { Link } from "react-router-dom";

function Cables() {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [firstRowDate, setFirstRowDate] = useState("");
  const [firstRowLocation, setFirstRowLocation] = useState("");
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cablesData, setCablesData] = useState([]);
  const { solution_id, update_solution } = useContext(solutionContext);
  const [solution, setSolution_id] = useState(solution_id);
  const [filteredDataTableData, setFilteredDataTableData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [originalDataTableData, setOriginalDataTableData] = useState([]);

  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (!solution) {
      const storedSolution = localStorage.getItem("solution");
      setSolution_id(storedSolution);
      update_solution(storedSolution);
    }
  }, [solution, update_solution]);

  function formatDate(date) {
    if (isNaN(date.getTime())) {
      // Invalid date
      return "Invalid date";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const fetchTableData = useCallback(async () => {
    if (!token) {
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;
    setFilteredDataTableData([]);
    setOriginalDataTableData([]);
    while (attempts < maxAttempts && !success) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/van_details/get_cables`,
          {
            access_token: token,
            user_log_id: userLogId,
          }
        );

        if (response.status === 200 && response.data.success) {
          const data = response.data.data.data || [];
          setFilteredDataTableData(data);
          setOriginalDataTableData(data);
          if (data.length > 0) {
            setFirstRowDate(data[0][7]); // Assuming the date is in the 8th column (index 7)
            setFirstRowLocation(data[0][1]); // Assuming the location is in the 2nd column (index 1)
          }
          success = true;
        } else {
          const errorMessage =
            response.data && response.data.msg
              ? response.data.msg
              : "Something went wrong";
          showFlashMessage(errorMessage);
        }
      } catch (error) {
        console.error("Table API:", error);
        showFlashMessage("Something went wrong");
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Something went wrong");
      setIsLoading(false);
    }
  }, [token, userLogId]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await fetchTableData();
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      };

      const delay = 200;
      const timerId = setTimeout(() => {
        fetchData();
      }, delay);

      return () => clearTimeout(timerId);
    }
  }, [token, fetchTableData]);
  const RouteMap = (row) => {
    localStorage.removeItem("location");
    localStorage.removeItem("cableType");
    localStorage.removeItem("cable");

    localStorage.setItem(
      "location",
      JSON.stringify({ label: row[1], value: row[1] })
    );
    localStorage.setItem(
      "cableType",
      JSON.stringify({ label: row[3], value: row[3] })
    );
    localStorage.setItem(
      "cable",
      JSON.stringify({ label: row[0], value: row[0] })
    );
  };
  const tableColumns = [
    {
      name: "Created At",
      selector: (row) => row[7],
      cell: (row) => formatDate(new Date(row[7])),
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row[1],
      sortable: true,
    },

    {
      name: "Type",
      selector: (row) => row[3],
      sortable: true,
    },
    {
      name: "Cable ID",
      selector: (row) => row[0],
      sortable: true,
    },
    {
      name: "Make",
      selector: (row) => row[4],
      sortable: true,
    },

    {
      name: "Rating",
      selector: (row) => row[5],
      sortable: true,
    },
    {
      name: "Length",
      selector: (row) => row[2],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={`/Cable Routes`}
          onClick={() => RouteMap(row)}
          style={{ color: "#000", fontSize: "16px" }}
        >
          <i className="fa fa-map-marker" title="View Map"></i>
        </Link>
      ),
      sortable: false,
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const NoDataComponent = () => (
    <>
      <table className="noDataTbl table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Type</th>
            <th>Cable ID</th>
            <th>Make</th>
            <th>Rating</th>
            <th>Length</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={tableColumns.length}>
              <div className="text-center">
                <p>No Data Available</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        whiteSpace: "normal",
      },
    },
    headRows: {
      style: {
        whiteSpace: "normal",
      },
    },
  };

  const handleSearch = (searchText) => {
    const searchTextLower = searchText.toLowerCase();
    const filteredData = originalDataTableData.filter((item) => {
      return (
        item[0].toLowerCase().includes(searchTextLower) ||
        item[1].toLowerCase().includes(searchTextLower) ||
        item[2].toLowerCase().includes(searchTextLower) ||
        item[3].toLowerCase().includes(searchTextLower) ||
        item[4].toLowerCase().includes(searchTextLower) ||
        item[5].toLowerCase().includes(searchTextLower) ||
        item[6].toLowerCase().includes(searchTextLower)
      );
    });
    setFilteredDataTableData(filteredData);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const customHeadings = tableColumns.map((column) => column.name);
    const filteredDataExcel = [];
    filteredDataTableData.forEach((row) => {
      filteredDataExcel.push({
        "Cable ID": row[0],
        Location: row[1],
        Length: row[2],
        Type: row[3],
        Make: row[4],
        Rating: row[5],
        Description: row[6],
        "Created At": formatDate(new Date(row[7])),
      });
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, "CablesData" + fileExtension);
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">{firstRowLocation}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#"> {firstRowDate ? formatDate(new Date(firstRowDate)) : ""}</a>
                      </li>
                    </ol>
                  </div>

                  <div className="mdlContent card-body">
                    <div className="col-md-12 col-lg-12">
                      <div className="card pr-card mb30">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="dataTables_length" id=""></div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                              <div className="form-inline dataTables_filter">
                                <div className="mr10">
                                  <label>
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder="Search..."
                                      value={searchText}
                                      onChange={(e) => {
                                        const searchText = e.target.value;
                                        setSearchText(e.target.value);
                                        handleSearch(e.target.value);
                                      }}
                                    />
                                  </label>
                                </div>
                                <div
                                  className="input-group pr-form-group"
                                  id=""
                                >
                                  <a
                                    className=""
                                    alt="Download XLS"
                                    onClick={exportToCSV}
                                  >
                                    <i className="fa fa-file-excel-o"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="">
                           
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "30%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
                            <DataTable
                              columns={tableColumns}
                              data={filteredDataTableData}
                              customStyles={customStyles}
                              pagination
                              fixedHeader
                              paginationComponentOptions={
                                paginationComponentOptions
                              }
                              progressPending={isLoading}
                              progressComponent={<div>Loading...</div>}
                              noDataComponent={<NoDataComponent />}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Cables;
