import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import solutionContext from "../../context/solution/solutionContext";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import EnergyLossBlockes from "./EnergyLossBlockes";
function EnergyLoss() {
  // State variables
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");

  // URL params
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");

  // Header state
  const [header, setHeader] = useState({ location: "", asset: "" });

  // Context
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);

  // Data states
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);

  // Selected values
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [onloadStartDate, setOnloadStartDate] = useState("");
  const [onloadEndDate, setOnloadEndDate] = useState("");
  // Date range states
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [isParam, setIsParam] = useState(false);
  // Loading states
  const [onload, setOnLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benchmarkLossKWH, setBenchmarkLossKWH] = useState("-");
  const [actualLossKWH, setActualLossKWH] = useState("-");
  const [benchmarkLossINR, setBenchmarkLossINR] = useState("-");
  const [actualLossINR, setActualLossINR] = useState("-");
  const [benchmarkLoss, setBenchmarkLoss] = useState("-");
  const [actualLoss, setActualLoss] = useState("-");
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    const initializeTokenData = () => {
      const tkData = Cookies.get("tkData");

      if (tkData) {
        const { token, userLogId } = JSON.parse(tkData);
        setToken(token);
        setUserLogId(userLogId);
      }
    };

    const initializeSolution = () => {
      if (solution === null) {
        setSolution(localStorage.getItem("solution"));
      }
    };

    initializeTokenData();
    initializeSolution();
  }, []);

  // after authentication and authorization it should load the respected account locations
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  // hooking the location change if previously we select the location then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  // hooking the asset change if previously we select the asset then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (
      onload === true &&
      token !== null &&
      token !== undefined &&
      token !== ""
    ) {
      const data = {
        location: onloadLoc,
        site: onloadAsset,
        // location: selectedLocation,
        // site: selectedAsset,
        iot_sol: "Transformer",
      };
      onLoadHandleSubmitData(data);
    }
  }, [onload]);

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);
  // ajax api for all locations
  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
            // } else if (response.status === 200 && response.data.success === false) {
            //   showFlashMessage(response.data.msg);
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  // ajax api for all assets depend upon location
  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer"
            }
          );

          setAssetData([]);

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));


            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];

        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset)
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  function hasDecimal(number) {
    // Compare the rounded version with the original number

    return number !== Math.floor(number);
  }

  function isPropertyPresent(obj, propertyPath) {
    if (!obj || typeof obj !== "object") {
      return "-";
    }

    const properties = propertyPath.split(".");
    let currentObject = obj;

    for (let prop of properties) {
      if (currentObject && currentObject.hasOwnProperty(prop)) {
        currentObject = currentObject[prop];
      } else {
        return "-";
      }
    }
    if (Number.isInteger(currentObject)) {
      return currentObject;
    } else if (typeof currentObject === "number") {
      return parseFloat(currentObject.toFixed(2));
    } else {
      return currentObject;
    }
  }

  // if (isLoading) {
  //   return (
  //     <div className="loaderWpr">
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const updateFromDate = (newFromDate) => {
    // setOnloadStartDate(formatDate(newFromDate));
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    // setOnloadEndDate(formatDate(newToDate));
    setToDate(newToDate);
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };
  const handleSubmitData = (props) => {
    setBenchmarkLossKWH("-");
    setActualLossKWH("-");
    setBenchmarkLossINR("-");
    setActualLossINR("-");
    setBenchmarkLoss("-");
    setActualLoss("-");
    setChartData([]);
    const { fromDate, toDate, location, site, parameter, group } = props;
    // setHeader({location: location.label, asset: site.label});
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate)
      };
      getEnergyLossData(data);
    }
  };

  const onLoadHandleSubmitData = (props) => {
    setBenchmarkLossKWH("-");
    setActualLossKWH("-");
    setBenchmarkLossINR("-");
    setActualLossINR("-");
    setBenchmarkLoss("-");
    setActualLoss("-");
    setChartData([]);
    const { fromDate, toDate, location, site } = props;
    // setHeader({location: location.label, asset: site.label});
    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer"
      };
      getEnergyLossData(data);
    }
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  const fetchData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }
          if (setData === "setChartData") {
            setData(response.data);
            success = true;
          } else {
            setData(response.data.data);
            success = true;
          }
        } else {
          setIsLoading(false)
          // showFlashMessage(`Data Not Available For ${errMSG}`);
        }
      } catch (error) {
        // alert(error);
        setIsLoading(false)
        // showFlashMessage(`Data Not Available For ${errMSG}`);

      }
      attempts++;
    }
    if (!success) {
      setIsLoading(false)
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };

  const getEnergyLossData = async (props) => {
    const requestData = props;
    setIsLoading(true);
    try {
      if (token !== null && token !== undefined && token !== "") {
        await fetchData(
          "iotrms/energy_loss/benchmark_energy_loss_kwh",
          setBenchmarkLossKWH,
          requestData,
          "Benchmark Energy Loss (kWh)"
        );
        setIsLoading(false);

        await fetchData(
          "iotrms/energy_loss/actual_energy_loss_kwh",
          setActualLossKWH,
          requestData,
          "Actual Energy Loss (kWh)"
        )
        await fetchData(
          "iotrms/energy_loss/benchmark_energy_loss_INR",
          setBenchmarkLossINR,
          requestData,
          "Benchmark Energy Loss (INR)"
        );
        await fetchData(
          "iotrms/energy_loss/actual_energy_loss_INR",
          setActualLossINR,
          requestData,
          "Actual Energy Loss (INR)"
        );
        await fetchData(
          "iotrms/energy_loss/benchmark_energy_loss_percent",
          setBenchmarkLoss,
          requestData,
          "Benchmark Energy Loss %"
        );
        await fetchData(
          "iotrms/energy_loss/actual_energy_loss_percent",
          setActualLoss,
          requestData,
          "Actual Energy Loss %"
        );
        await fetchData(
          "iotrms/energy_loss/benchmark_vs_actual_energy_loss",
          setChartData,
          requestData,
          "Energy Loss (kWh)"
        );

      }
    } catch (error) {
      // Handle errors if needed
      console.error(error);
      setIsLoading(false); // Ensure isLoading is set to false in case of an error
    }
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return (
    <>
      {isLoading && (
        <div className="loaderWpr">
          <div className="loader"></div>
        </div>
      )}
      
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "35%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
      <div>
        <div className="wrapper">
          {/* <!-- Content Wrapper. Contains page content --> */}
          <div className="content-wrapper">
            {/* <!-- Main content --> */}
            <section className="content">
              <div className="container-fluid">
                {/* <div className="content-header">
                
                                 </div> */}
                <div className="mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">{selectedAsset.label}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">{selectedLocation.label}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">
                          {" "}
                          {onloadStartDate ? (
                            <a href="#">{onloadStartDate}</a>
                          ) : (
                            <a href="#">{formatDate(fromDate)}</a>
                          )}
                          &nbsp;-&nbsp;
                          {onloadEndDate ? (
                            <a href="#">{onloadEndDate}</a>
                          ) : (
                            <a href="#">{formatDate(toDate)}</a>
                          )}
                        </a>
                      </li>
                    </ol>
                  </div>
                  <EnergyLossBlockes
                    benchmarkLossKWH={benchmarkLossKWH}
                    actualLossKWH={actualLossKWH}
                    benchmarkLossINR={benchmarkLossINR}
                    actualLossINR={actualLossINR}
                    benchmarkLoss={benchmarkLoss}
                    actualLoss={actualLoss}
                    chartData={chartData}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Sidebar
        locationData={locationData}
        assetData={assetData}
        // parameterData={parameterData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        // changeParameter={changeParameter}
        handleSubmitData={handleSubmitData}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        // selectedParameter={selectedParameter}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        // isParam={isParam}
        // changeIsParam={changeIsParam}
        // handleAddGroup={handleAddGroup}
        // groupData={groupData}
        // getGroupName={getGroupName}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default EnergyLoss;
