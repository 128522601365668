/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "react-bootstrap";
import Sidebar from "./Sidebar";
import moment from 'moment';


function EnergyConsum() {
  const [pieData, setpieData] = useState([]);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("")
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const [energyData, setenergyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newLoad, setNewLoad] = useState(false)
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [onload, setOnLoad] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
  const [toDate, setToDate] = useState(new Date(moment(new Date())));

  const [current_hv_max, setCurrent_hv_max] = useState(["-", "-", "-"]);
  const [current_hv_max_datetime, setCurrent_hv_max_datetime] = useState(["-", "-", "-"]);
  const [current_hv_min, setCurrent_hv_min] = useState(["-", "-", "-"]);
  const [current_hv_min_datetime, setCurrent_hv_min_datetime] = useState(["-", "-", "-"]);
  const [current_hv_avg, setCurrent_hv_avg] = useState(["-", "-", "-"]);

  const [voltage_hv_max, setVoltage_hv_max] = useState(["-", "-", "-"]);
  const [voltage_hv_max_datetime, setVoltage_hv_max_datetime] = useState(["-", "-", "-"]);
  const [voltage_hv_min, setVoltage_hv_min] = useState(["-", "-", "-"]);
  const [voltage_hv_min_datetime, setVoltage_hv_min_datetime] = useState(["-", "-", "-"]);
  const [voltage_hv_avg, setVoltage_hv_avg] = useState(["-", "-", "-"]);

  const [voltage_lv_max, setVoltage_lv_max] = useState(["-", "-", "-"]);
  const [voltage_lv_max_datetime, setVoltage_lv_max_datetime] = useState(["-", "-", "-"]);
  const [voltage_lv_min, setVoltage_lv_min] = useState(["-", "-", "-"]);
  const [voltage_lv_min_datetime, setVoltage_lv_min_datetime] = useState(["-", "-", "-"]);
  const [voltage_lv_avg, setVoltage_lv_avg] = useState(["-", "-", "-"]);

  const [current_lv_max, setCurrent_lv_max] = useState(["-", "-", "-"]);
  const [current_lv_max_datetime, setCurrent_lv_max_datetime] = useState(["-", "-", "-"]);
  const [current_lv_min, setCurrent_lv_min] = useState(["-", "-", "-"]);
  const [current_lv_min_datetime, setCurrent_lv_min_datetime] = useState(["-", "-", "-"]);
  const [current_lv_avg, setCurrent_lv_avg] = useState(["-", "-", "-"]);

  const [voltage, setVoltage] = useState(null);
  const [temperature, setTemperature] = useState(null);


  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {

      }
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId)
    }
  }, []);



  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    }
    else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        // setSelectedAsset(new_asset);
        setOnloadAsset(new_asset)
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      }
      else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          }
          else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          }
          else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const handleSubmitData = async (props) => {
    const { location, site } = props;

    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // start_date: convertDateCloneApi(fromDate),
        // end_date: convertDateCloneApi(toDate),
        location_id: location.value,
        assset_id: site.value,
        iot_sol: "Transformer"
      };

      try {
        await daily_summary_api(data);
        await voltage_current_temp(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }

  const SubmitDataWithDate = async (props) => {
    const { fromDate, toDate, location, site, iot_sol } = props;
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate),
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer"
      };
      try {
        await daily_summary_api(data);
        await voltage_current_temp(data);
      } catch (error) {
        console.error("Error making API calls:", error);
      }
    }
  }

  const [volt_current_temp, setVolt_current_temp] = useState()
  const voltDatas = volt_current_temp && volt_current_temp.length > 0 ? volt_current_temp[0] : null;

  const voltage_current_temp = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      // setIsLoading(true);
      try {
        if (!token) {
          // Return early if token is not available
          return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/daily_summary_v_and_c_and_temp`
          , data
          // {
          //   "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiNWM2ZDE3ZGEwOTU4NjQ3MDY1ZmU3MDkyIiwidXNlcl9pZCI6IjYwODdhOTU2MDk1ODY0MDgwNzQ4MGQwMyIsInVzZXJuYW1lIjoiTmlraXRhIGtvcmRlIiwiaWF0IjoxNzAxNzY5Mjg5LCJleHAiOjE3MDIxMTQ4ODl9.ILPxiRwRDHOznD7Y84uYsF2IL1VpmkHgVx3Np0nF7Ds",
          //   "user_log_id": "656ef03980c3e44f7733c534",
          //   "start_date": "2023-12-01 00:00",
          //   "end_date": "2023-12-05 23:59",
          //   "iot_sol": "Transformer",
          //   "location_id": "62e8e8000958645f412b6f55",
          //   "assset_id": "62e8e8430958645f45d43c8d"
          // }
        );

        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setVolt_current_temp(response.data.data)
          success = true;
          setCurrent_hv_max_datetime(["-", "-", "-"]);
          setCurrent_hv_min(["-", "-", "-"]);
          setCurrent_hv_min_datetime(["-", "-", "-"]);
          setCurrent_hv_avg(["-", "-", "-"]);

          setVoltage_hv_max(["-", "-", "-"]);
          setVoltage_hv_max_datetime(["-", "-", "-"]);
          setVoltage_hv_min(["-", "-", "-"]);
          setVoltage_hv_min_datetime(["-", "-", "-"]);
          setVoltage_hv_avg(["-", "-", "-"]);

          setVoltage_lv_max(["-", "-", "-"]);
          setVoltage_lv_max_datetime(["-", "-", "-"]);
          setVoltage_lv_min(["-", "-", "-"]);
          setVoltage_lv_min_datetime(["-", "-", "-"]);
          setVoltage_lv_avg(["-", "-", "-"]);

          setCurrent_lv_max(["-", "-", "-"]);
          setCurrent_lv_max_datetime(["-", "-", "-"]);
          setCurrent_lv_min(["-", "-", "-"]);
          setCurrent_lv_min_datetime(["-", "-", "-"]);
          setCurrent_lv_avg(["-", "-", "-"]);

          // setCurrent_hv_min(["-","-", "-"]);
          // setCurrent_hv_min_datetime(["-","-", "-"]);

        }
        else {
          setVolt_current_temp([])
          // showFlashMessage("Data Not Available For Voltage ( V ) , Current ( A ) and Temperature ( °C )", "danger")
          setCurrent_hv_max_datetime(["-", "-", "-"]);
          setCurrent_hv_min(["-", "-", "-"]);
          setCurrent_hv_min_datetime(["-", "-", "-"]);
          setCurrent_hv_avg(["-", "-", "-"]);

          setVoltage_hv_max(["-", "-", "-"]);
          setVoltage_hv_max_datetime(["-", "-", "-"]);
          setVoltage_hv_min(["-", "-", "-"]);
          setVoltage_hv_min_datetime(["-", "-", "-"]);
          setVoltage_hv_avg(["-", "-", "-"]);

          setVoltage_lv_max(["-", "-", "-"]);
          setVoltage_lv_max_datetime(["-", "-", "-"]);
          setVoltage_lv_min(["-", "-", "-"]);
          setVoltage_lv_min_datetime(["-", "-", "-"]);
          setVoltage_lv_avg(["-", "-", "-"]);

          setCurrent_lv_max(["-", "-", "-"]);
          setCurrent_lv_max_datetime(["-", "-", "-"]);
          setCurrent_lv_min(["-", "-", "-"]);
          setCurrent_lv_min_datetime(["-", "-", "-"]);
          setCurrent_lv_avg(["-", "-", "-"]);

          // setIsLoading(false);
        }
      } catch (error) {

        showFlashMessage("Error fetching energy data", "danger");
        // setIsLoading(false);

      }
      attempts++;
    }
    if (!success) {
      setVolt_current_temp([])
      showFlashMessage("Data Not Available For Voltage ( V ) , Current ( A ) and Temperature ( °C )", "danger")
    }
    // setIsLoading(false);
  }
  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  const [newStartDate, setNewStartDate] = useState()
  const [newEndDate, setNewEndDate] = useState()
  const [daily_summary_param, setDaily_summary_param] = useState()
  const energyDatas = daily_summary_param && daily_summary_param.length > 0 ? daily_summary_param[0] : null;
  const daily_summary_api = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      const data = props;
      setIsLoading(true);
      if (data.start_date && data.end_date) {
        setNewStartDate(breadcrum_date_converter(data.start_date));
        setNewEndDate(breadcrum_date_converter(data.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/daily_summary/daily_summary_parameters`,
          data
        )
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          setDaily_summary_param(response.data.data);
          success = true;
          setIsLoading(false);
          if (response.data.start_date && response.data.end_date) {
            setNewStartDate(response.data.start_date);
            setNewEndDate(response.data.end_date);
          }
        } else {
          setIsLoading(false);
          setDaily_summary_param([]);
          // showFlashMessage("Data Not Available For Daily Summary Parameters", "danger");
        }
      } catch (error) {
        setIsLoading(false);
        setDaily_summary_param([]);
        showFlashMessage("Error fetching energy data", "danger");
      }
      attempts++;
    }
    if (!success) {
      setIsLoading(false);
      setDaily_summary_param([]);
      showFlashMessage("Data Not Available For Daily Summary Parameters", "danger");
    }
  };


  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // Extracting voltage data
  const hvMaxData = voltDatas && voltDatas.voltage && voltDatas.voltage.hv_max && voltDatas.voltage.hv_max.length > 0
    ? voltDatas.voltage.hv_max : []

  hvMaxData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const hvAvgData = voltDatas && voltDatas.voltage && voltDatas.voltage.hv_avg && voltDatas.voltage.hv_avg.length > 0
    ? voltDatas.voltage.hv_avg : []

  hvAvgData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const hvMinData = voltDatas && voltDatas.voltage && voltDatas.voltage.hv_min && voltDatas.voltage.hv_min.length > 0
    ? voltDatas.voltage.hv_min : []

  hvMinData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const lvAvgData = voltDatas && voltDatas.voltage && voltDatas.voltage.lv_avg && voltDatas.voltage.lv_avg.length > 0
    ? voltDatas.voltage.lv_avg : []

  lvAvgData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const lvMaxData = voltDatas && voltDatas.voltage && voltDatas.voltage.lv_max && voltDatas.voltage.lv_max.length > 0
    ? voltDatas.voltage.lv_max : []

  lvMaxData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const lvMinData = voltDatas && voltDatas.voltage && voltDatas.voltage.lv_min && voltDatas.voltage.lv_min.length > 0
    ? voltDatas.voltage.lv_min : []

  lvMinData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  // Extracting current data
  const currhvMaxData = voltDatas && voltDatas.current && voltDatas.current.hv_max && voltDatas.current.hv_max.length > 0
    ? voltDatas.current.hv_max : []

  currhvMaxData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const currhvAvgData = voltDatas && voltDatas.current && voltDatas.current.hv_avg && voltDatas.current.hv_avg.length > 0
    ? voltDatas.current.hv_avg : []

  currhvAvgData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const currhvMinData = voltDatas && voltDatas.current && voltDatas.current.hv_min && voltDatas.current.hv_min.length > 0
    ? voltDatas.current.hv_min : []

  currhvMinData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const currlvAvgData = voltDatas && voltDatas.current && voltDatas.current.lv_avg && voltDatas.current.lv_avg.length > 0
    ? voltDatas.current.lv_avg : []

  currlvAvgData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const currlvMaxData = voltDatas && voltDatas.current && voltDatas.current.lv_max && voltDatas.current.lv_max.length > 0
    ? voltDatas.current.lv_max : []

  currlvMaxData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const currlvMinData = voltDatas && voltDatas.current && voltDatas.current.lv_min && voltDatas.current.lv_min.length > 0
    ? voltDatas.current.lv_min : []

  currlvMinData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  // Extracting temp data
  const temphvMaxData = voltDatas && voltDatas.temp && voltDatas.temp.max && voltDatas.temp.max.length > 0
    ? voltDatas.temp.max : []

  temphvMaxData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });

  const temphvMinData = voltDatas && voltDatas.temp && voltDatas.temp.min && voltDatas.temp.min.length > 0
    ? voltDatas.temp.min : []

  temphvMinData.forEach((data, index) => {
    data.forEach((item) => {
      const value = Object.values(item)[0] // Accessing the value within the object
    });
  });



  // Generating table rows for hv_max
  // const hvMaxTableRows = 

  const truncateToThreeDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = parseFloat(Number(value).toFixed(1));
      return isNaN(truncatedValue) ? '-' : truncatedValue;
    }
    return '';
  };

  const truncateToTwoDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = parseFloat(Number(value).toFixed(2));
      return isNaN(truncatedValue) ? '-' : truncatedValue;
    }
    return '';
  };

  // Function to format time
  const formatTime = (timeString) => {
    if (!timeString || timeString === '-') {
      return '-';
    }
    // return new Date(new Date(timeString).toISOString()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const timestamp = timeString;
    const date = new Date(timestamp);

    const day = ('0' + date.getUTCDate()).slice(-2);
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const year = date.getUTCFullYear();
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    // const seconds = ('0' + date.getUTCSeconds()).slice(-2);

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  // date format for breadcrums
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const updateFromDate = (newFromDate) => {
    setFromDate(newFromDate);
    // setNewStartDate(formatDate(newFromDate));
  };

  const updateToDate = (newToDate) => {
    setToDate(newToDate);
    // setNewEndDate(formatDate(newToDate));
  };

  return (
    <>
      {isLoading && (
        <div className="loaderWpr">
          <div className="loader"></div>
        </div>
      )}
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
       
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "40%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">

                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {newStartDate ? (
                          <a href="#">{newStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {newEndDate ? (
                          <a href="#">{newEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li>
                  </ol>
                </div>


                <div className="dlySmryPg mdlContent card-body">

                  <div className="row">
                    <div className="col-md-12 col-lg-9">
                      <div className="energyWpr card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Energy Consumption</h5>
                        </div>
                        <div className="inrCardBody">
                          <div className="row">
                            <div className="col-md-3 col-lg-3">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>
                                    HV Active Energy (kWh)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.energy_consumpation.hv_active_energy_kwh)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    HV Apparent Energy (kVAh)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.energy_consumpation.hv_apparent_energy_kvah)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    LV Active Energy (kWh)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.energy_consumpation.lv_active_energy_kwh)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    LV Apparent Energy (kVAh)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.energy_consumpation.lv_apparent_energy_kvah)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="powerWpr card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Power Consumption (Max)</h5>
                        </div>

                        <div className="inrCardBody">
                          <div className="row">
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    HV Total Active Power (kW)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.hv_total_active_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    HV Total Apparent Power (kVA)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.hv_total_apparent_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>HV Total Reactive Power (kVAR)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.hv_total_reactive_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    LV Total Active Power (kW)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.lv_total_active_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>

                                    LV Total Apparent Power (kVA)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.lv_total_apparent_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                              <div className="enrgConsBox">
                                <div className="smlCardHdr">
                                  <h5>LV Total Reactive Power (kVAR)</h5>
                                </div>
                                <div className="inrCardBody">
                                  {newLoad ? (
                                    {/* <p>Loading...</p> */ }
                                  ) : energyDatas ? (
                                    <>
                                      <h3>{truncateToThreeDigits(energyDatas.power_consumption.lv_total_reactive_power)}</h3>
                                    </>
                                  ) : (
                                    <><h3>-</h3></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-12 col-lg-3">
                      <div className="card pr-card mb30">
                        <div className="relayTbl">
                          <div className="inrCardHdr">
                            <h5>Relay Status</h5>
                          </div>
                          <div className="inrCardBody">
                            <div className="">
                              <table className="table table-bordered">
                                <div className="relayTblscroll">
                                  {energyDatas && (
                                    <tbody>
                                      {Object.entries(energyDatas.relay_status).map(([field, value]) => {
                                        const formattedField = field
                                        {/* .split('_')
                                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                          .join(' '); */}

                                        return (
                                          <tr key={field}>
                                            <th style={{ color: "#000" }}>{formattedField}</th>
                                            <td style={{ color: "#000" }}>{value}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  )}
                                </div>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='row'>
                    <div className="col-md-12 col-lg-4">
                      <div className="voltageCard card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Voltage ( V )</h5>
                        </div>
                        <div className="inrCardBody">
                          <div className="tblScroll">
                            <table className="table table-bordered table-height">
                              <thead>
                                <th className="minWth"></th>
                                <th className="rBg text-center">RY</th>
                                <th className="yBg text-center">YB</th>
                                <th className="bBg text-center">BR</th>
                              </thead>
                              <tbody>
                                {hvAvgData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Avg</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                {hvMaxData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Max</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {hvMinData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Min</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {lvAvgData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Avg</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                {lvMaxData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Max</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {lvMinData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Min</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <div className="currentCard card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Current ( A )</h5>
                        </div>
                        <div className="inrCardBody">
                          <div className="tblScroll">
                            <table className="table table-bordered table-height">
                              <thead>
                                <th className="minWth"></th>
                                <th className="rBg text-center">R</th>
                                <th className="yBg text-center">Y</th>
                                <th className="bBg text-center">B</th>
                              </thead>
                              <tbody>
                                {currhvAvgData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Avg</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                {currhvMaxData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Max</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {currhvMinData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">HV - Min</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {currlvAvgData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Avg</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                    </tr>
                                    <tr>
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                {currlvMaxData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Max</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                                {currlvMinData.map((row, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      {index === 0 && (
                                        <>
                                          <th rowSpan="2">LV - Min</th>
                                          {row.map((item, idx) => (
                                            <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                          ))}
                                        </>
                                      )}
                                      {index === 1 && (
                                        <>
                                          {row.map((item, idx) => (
                                            <td className="tdtimeBg" key={idx}>
                                              {formatTime(Object.values(item)[0])}
                                            </td>))}
                                        </>
                                      )}
                                    </tr>

                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-4">
                      <div className="tmpTbl card pr-card">
                        <div className="inrCardHdr">
                          <h5>Temperature ( <sup>o</sup>C )</h5>
                        </div>
                        <div className="inrCardBody">
                          <table className="table table-bordered">
                            <thead>
                              <th></th>
                              <th className="yBg text-center">WTI LV</th>
                              <th className="yBg text-center">OTI</th>
                              <th className="yBg text-center">WTI HV</th>
                            </thead>
                            <tbody>
                              {temphvMaxData.map((row, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    {index === 0 && (
                                      <>
                                        <th rowSpan="2">Max</th>
                                        {row.map((item, idx) => (
                                          <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                        ))}
                                      </>
                                    )}

                                    {index === 1 && (
                                      <>
                                        {row.map((item, idx) => (
                                          <td className="tdtimeBg" key={idx}>
                                            {formatTime(Object.values(item)[0])}
                                          </td>))}
                                      </>
                                    )}
                                  </tr>
                                </React.Fragment>
                              ))}
                              {temphvMinData.map((row, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    {index === 0 && (
                                      <>
                                        <th rowSpan="2">Min</th>
                                        {row.map((item, idx) => (
                                          <td className="valueFont" key={idx}>{truncateToThreeDigits(Object.values(item)[0])}</td>
                                        ))}
                                      </>
                                    )}
                                    {index === 1 && (
                                      <>
                                        {row.map((item, idx) => (
                                          <td className="tdtimeBg" key={idx}>
                                            {formatTime(Object.values(item)[0])}
                                          </td>))}
                                      </>
                                    )}
                                  </tr>

                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>


                      <div className="pFactor card pr-card mb30">
                        <div className="inrCardHdr">
                          <h5>Power Factor</h5>
                        </div>
                        <div className="inrCardBody">
                          <table className="pfTbl table table-bordered">
                            <thead>
                              <tr>
                                <th className="pfWidth"></th>
                                <th className="text-center">Max</th>
                                <th className="text-center">Min</th>
                                <th className="text-center">Avg</th>
                              </tr>
                            </thead>
                            {energyDatas && (
                              <tbody>
                                {Object.keys(energyDatas.power_factor).map(type => (
                                  <tr key={type}>
                                    <th>{type.toUpperCase()}</th>
                                    {energyDatas.power_factor[type].map((value, index) => (
                                      <td className="valueFont" key={index}>{truncateToTwoDigits(value)}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
      <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={SubmitDataWithDate}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default EnergyConsum;
