import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import solutionContext from "../../context/solution/solutionContext";
import { useContext } from "react";
import Chart from "react-apexcharts";
import EnergyBlockes from "./EnergyBlockes";
import { Alert } from "react-bootstrap";
import moment from "moment";
import { data } from "jquery";

function EnergyDelivered() {
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [flashMessageVariant, setFlashMessageVariant] = useState("danger");
  const params = new URLSearchParams(useLocation().search);
  const param_asset = params.get("as");
  const param_location = params.get("lc");
  const [header, setHeader] = useState({ locaton: "", asset: "" });
  const { solution_id } = useContext(solutionContext);
  const [solution, setSolution] = useState(solution_id);
  const [locationData, setLocationData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("")
  const [onloadAsset, setOnloadAsset] = useState("")
  const [fromDate, setFromDate] = useState(
    new Date(moment(new Date()).subtract(1, "days"))
  );
  const [toDate, setToDate] = useState(new Date(moment(new Date())));
  const [onload, setOnLoad] = useState(false);
  const [isParam, setIsParam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benchmarkKWH, setBenchmarkKWH] = useState("-");
  const [actualKWH, setActualKWH] = useState("-");
  const [benchmarkINR, setBenchmarkINR] = useState("-");
  const [actualINR, setActualINR] = useState("-");
  const [productivityLoss, setProductivityLoss] = useState("-");
  const [chartData, setChartData] = useState([]);
  const [onloadStartDate, setOnloadStartDate] = useState();
  const [onloadEndDate, setOnloadEndDate] = useState();

  // initial load and find cookies for authorization and authentication
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }

    if (solution === null) {
      setSolution(localStorage.getItem("solution"));
    }
  }, []);

  // after authentication and authorization it should load the respected account locations
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  // hooking the location change if previously we select the location then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  // hooking the asset change if previously we select the asset then it automatically changes
  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  // ajax api for all locations
  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          }
          else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Locations are Not Found");
    }
  };

  // ajax api for all assets depend upon location
  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              location_id: props,
              iot_sol: "Transformer"
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              // setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
        }
      } catch (error) {
        setAssetData([]);
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location)
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location)
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset)
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset)
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  // hooking of location and asset then hit data api
  useEffect(() => {
    if (
      onload === true &&
      token !== null &&
      token !== undefined &&
      token !== ""
    ) {
      let data = {
        location: onloadLoc,
        site: onloadAsset,
        // location: selectedLocation,
        // site: selectedAsset,
        iot_sol: "Transformer",
      };
      // handleSubmitData(data);
      onLoadHandleSubmitData(data);
    }
  }, [onload]);

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props)
    // setSelectedAsset({});
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    // localStorage.setItem("asset", JSON.stringify(props));
    setOnloadAsset(props);
  };

  const updateFromDate = (newFromDate) => {
    // setOnloadStartDate(formatDate(newFromDate));
    setFromDate(newFromDate);
  };

  const updateToDate = (newToDate) => {
    // setOnloadEndDate(formatDate(newToDate));
    setToDate(newToDate);
  };

  const convertDateCloneApi = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  };

  const onLoadHandleSubmitData = (data) => {
    setBenchmarkKWH("-");
    setActualKWH("-");
    setBenchmarkINR("-");
    setActualINR("-");
    setProductivityLoss("-");
    setChartData([]);
    const { location, site } = data;
    // setHeader({ location: location.label, asset: site.label });
    setSelectedLocation(location);
    setSelectedAsset(site);

    setOnloadLoc(location)
    setOnloadAsset(site)

    let load_data = {
      access_token: token,
      user_log_id: userLogId,
      location_id: location.value,
      assset_id: site.value,
      iot_sol: "Transformer",
    };
    getEnergyData(load_data);
  }

  const handleSubmitData = async (props) => {
    setBenchmarkKWH("-");
    setActualKWH("-");
    setBenchmarkINR("-");
    setActualINR("-");
    setProductivityLoss("-");
    setChartData([]);
    const { fromDate, toDate, location, site } = props;
    // setHeader({ location: location.label, asset: site.label });
    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));
    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      let data = {
        access_token: token,
        user_log_id: userLogId,
        location_id: onloadLoc.value,
        assset_id: onloadAsset.value,
        iot_sol: "Transformer",
        start_date: convertDateCloneApi(fromDate),
        end_date: convertDateCloneApi(toDate)
      };
      getEnergyData(data);

      // try {
      //   if (isParam === false) {
      //     data = Object.assign(
      //       {},
      //       data
      //       // { sensor_parameters: selectedParameter.map((d) => d.value), }
      //     );
      //     getEnergyData(data);
      //     // getDatableData(data);
      //   } else {
      //     data = Object.assign({}, data, {
      //       group_id: group.value,
      //       iot_sol: "Transformer",
      //       // localStorage.getItem("iot_solution_master_id")
      //     });
      //     getEnergyData(data);
      //     // getDatableData(data);
      //   }
      // } catch (error) {}
      // data = (isParam === false) ? Object.assign({}, data, { sensor_parameters: parameter }) : data;
      // alert("done");
    }
  };

  const [alerts, setAlerts] = React.useState([]);
  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  // if (isLoading) {
  //   return (
  //     <div className="loaderWpr">
  //       <div className="loader"></div>
  //     </div>
  //   );
  // }

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(' ');
    let [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  }

  const fetchData = async (url, setData, requestData, errMSG) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {

      // setIsLoading(true);
      if (requestData.start_date && requestData.end_date) {
        setOnloadStartDate(breadcrum_date_converter(requestData.start_date));
        setOnloadEndDate(breadcrum_date_converter(requestData.end_date));
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/${url}`,
          requestData
        );
        if (response.data.success === true) {
          if (response.data.start_date && response.data.end_date) {
            setOnloadStartDate(response.data.start_date);
            setOnloadEndDate(response.data.end_date);
          }
          if (setData === "setChartData") {
            setIsLoading(false);
            setData(response.data);
          } else {
            setIsLoading(false);
            setData(response.data.data);
            success = true;
          }
        } else {
          setIsLoading(false);
          // showFlashMessage(`Data Not Available For ${errMSG}`);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        // showFlashMessage(`Data Not Available For ${errMSG}`);

      } attempts++;
    }
    if (!success) {
      setIsLoading(false);
      showFlashMessage(`Data Not Available For ${errMSG}`);
    }
  };



  const getEnergyData = async (props) => {
    const requestData = props;
    setIsLoading(true);
    if (token !== null && token !== undefined && token !== "") {
      await fetchData(
        "iotrms/energy_delivered/benchmark_energy_delivered_kwh",
        setBenchmarkKWH,
        requestData,
        "Benchmark energy delivered kwh"
      );

      await fetchData(
        "iotrms/energy_delivered/actual_energy_delivered_kwh",
        setActualKWH,
        requestData,
        "Actual energy delivered kwh"
      );

      await fetchData(
        "iotrms/energy_delivered/benchmark_energy_delivered_kwh_INR",
        setBenchmarkINR,
        requestData,
        "Benchmark energy delivered kwh INR"
      );

      await fetchData(
        "iotrms/energy_delivered/actual_energy_delivered_INR",
        setActualINR,
        requestData,
        "Actual energy delivered INR"
      );

      await fetchData(
        "iotrms/energy_delivered/benchmark_vs_actual_energy_delivered",
        setChartData,
        requestData,
        "Benchmark vs actual energy delivered"
      );
    }
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return (
    <>
      {isLoading && (
        <div className="loaderWpr">
          <div className="loader"></div>
        </div>
      )}
     
{alerts.map((alert,index) => (
          <Alert
    key={alert.id}
    id={`flash-message-${alert.id}`}
    variant={alert.variant === "success" ? "success" : "danger"}
    onClose={() => setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alert.id))}
    dismissible
    style={{width: "35%", marginBottom: `${index * 25 * 2}px` }} 
  >
            {alert.message}
          </Alert>
        ))}
      <div className="wrapper">
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              {/* <div className="content-header">
                
              </div> */}
              <div className="mdlContent card pr-card mb30">
                <div className="breadcrumbHdr card-header">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{selectedAsset.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">{selectedLocation.label}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">
                        {" "}
                        {onloadStartDate ? (
                          <a href="#">{onloadStartDate}</a>
                        ) : (
                          <a href="#">{formatDate(fromDate)}</a>
                        )}
                        &nbsp;-&nbsp;
                        {onloadEndDate ? (
                          <a href="#">{onloadEndDate}</a>
                        ) : (
                          <a href="#">{formatDate(toDate)}</a>
                        )}
                      </a>
                    </li>


                  </ol>
                </div>
                <EnergyBlockes
                  benchmarkKWH={benchmarkKWH}
                  actualKWH={actualKWH}
                  benchmarkINR={benchmarkINR}
                  actualINR={actualINR}
                  // productivityLoss={productivityLoss}
                  chartData={chartData}
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      <Sidebar
        locationData={locationData}
        assetData={assetData}
        // parameterData={parameterData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        // changeParameter={changeParameter}
        handleSubmitData={handleSubmitData}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
        // selectedParameter={selectedParameter}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        // isParam={isParam}
        // changeIsParam={changeIsParam}
        // handleAddGroup={handleAddGroup}
        // groupData={groupData}
        // getGroupName={getGroupName}
        updateFromDate={updateFromDate}
        updateToDate={updateToDate}
      />
    </>
  );
}

export default EnergyDelivered;
